const NO_RETURN_JSON_OR_TEXT = ['/addCase', '/premmiar/updateUser']

class ApiService {
  constructor(baseURL) {
    this.baseURL = baseURL
  }

  setBaseUrl(url){
    this.baseURL = url
  }

  setUserToken(token){
    this.token = token
  }

  

  async request(endpoint, method = 'GET', data = null, headers = {}, returnJson = true) {
    const url = `${this.baseURL}${endpoint}`;

    const config = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token ?? this.unloggedToken,
        ...headers
      }
    };

    if (data) {
      config.body = JSON.stringify(data);
    }

    try {
      const response = await fetch(url, config);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const filteredReturn = NO_RETURN_JSON_OR_TEXT.filter(function (str) { 
        return endpoint.includes(str); 
      });

      if (filteredReturn?.length) {
        return { success: true }
      } else {
        const ret = returnJson ? await response.json() : await response.text();
        try {
          return ret
        } catch (error) {
          console.log('ret -->', error, ret)
        }
        return 
      }
    } catch (error) {
      console.log('endpoint -->', endpoint, error)
      throw error;
    }
  }

  get(endpoint, returnJson = true, headers = {}) {
    return this.request(endpoint, 'GET', null, headers, returnJson);
  }

  post(endpoint, data, returnJson = true, headers = {}) {
    return this.request(endpoint, 'POST', data, headers, returnJson);
  }

  put(endpoint, data, returnJson = true, headers = {}) {
    return this.request(endpoint, 'PUT', data, headers, returnJson);
  }

  delete(endpoint, returnJson = true, headers = {}) {
    return this.request(endpoint, 'DELETE', null, headers, returnJson);
  }
}

export default ApiService;