import React, { useMemo, useState } from "react";
import styled from "@emotion/styled";
import SideTitle from "../Atoms/SideTitle";
import ToolHelper from "../utils/ToolHelper";
import NoResultsFound from "../Molecules/NoResultsFound";
import { X, User, Check } from "react-feather";
import { BREAKPOINTS } from "../configs/consts";
import defaultUser from "../assets/profile1v4.png";

const Notifications = (props) => {
    const [notifications, setNotifications] = useState(props.refNotifications);

    const { history } = props;

    const config = useMemo(() => ToolHelper.getThemedConfig(props, 'Points.preset'), [props.config]);

    const markAllAsRead = async () => {
        const refs = notifications.map(({ ref }) => ref);
        setNotifications([]);
        props.refSetNotifications([]);
        await props.userHelper.markNotificationsAsRead(refs);
    }

    const markOneAsRead = async (ref, id) => {
        setNotifications(notifications.filter((n) => n.Id !== id));
        props.refSetNotifications(notifications.filter((n) => n.Id !== id));
        await props.userHelper.markNotificationsAsRead([ref]);
    }

    const markSubTypeAsRead = async (subType) => {
        const refs = notifications.filter(({ acg_SubType__c }) => acg_SubType__c === subType).map(({ ref }) => ref);
        setNotifications(notifications.filter(({ acg_SubType__c }) => acg_SubType__c !== subType));
        props.refSetNotifications(notifications.filter(({ acg_SubType__c }) => acg_SubType__c !== subType));
        await props.userHelper.markNotificationsAsRead(refs);
    }

    return (
        <StyledNotifications config={config} defaultUser={defaultUser}>
            <SideTitle config={config}>Notificações</SideTitle>
            {notifications?.length > 0 ? (
                <div>
                    <div className="mark-as-read" onClick={markAllAsRead}>
                        <p>Marcar todas como lidas</p>
                    </div>
                    {notifications.map((n) => (
                        <div
                            className="notification"
                            key={n.Id}
                            style={{
                                ...(n.acg_isClickable__c
                                    && {
                                    backgroundColor: config.themeLayout.buttonSecondaryBackground,
                                    border: `2px solid ${config.themeLayout.buttonBackground}`
                                }
                                )
                            }}
                        >
                            <div className="mark-one-as-read">
                                <p>{n.CreatedDate?.split("T")[0].split("-").reverse().join("/")}</p>
                                <X
                                    width={20}
                                    height={20}
                                    color={config?.themeLayout?.buttonBackground}
                                    strokeWidth={3}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => markOneAsRead(n.ref, n.Id)}
                                />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10 }}>
                                <div style={{ width: "20%" }}>
                                    <div className={`photo ${(n.ImageUrl__c ? " default-bg" : "")}`}>
                                        {n.ImageUrl__c ? (
                                            <img src={n.ImageUrl__c} alt="user avatar" />
                                        ) : (
                                            <div className="default-user" />
                                        )}
                                    </div>
                                </div>
                                <span style={{ width: "80%", color: "#000" }}>{n.Description__c}</span>
                            </div>
                            {n.acg_isClickable__c && (
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <div
                                        className="accept-invite"
                                        onClick={() => {
                                            props.rightPanel.setIsOpen(false);
                                            history.push("/grupo-de-pontos", {
                                                openApplications: true,
                                            });
                                            markSubTypeAsRead(n.acg_SubType__c);
                                        }}
                                    >
                                        <p>Aceitar convite</p>
                                        <Check
                                            width={20}
                                            height={20}
                                            strokeWidth={3}
                                            color={config.themeLayout.buttonBackground}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <div className="no-results">
                    <NoResultsFound
                        config={config}
                        title="Nenhuma notificação encontrada"
                    />
                </div>
            )}
        </StyledNotifications>
    )
}

const StyledNotifications = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 40px;
    color: ${props => props.config?.themeLayout?.text};
    background-color: #ffffff !important;
    width: calc(100% - 520px);

    @media (max-width: ${BREAKPOINTS.PHONE}px){
        width: 100%;
    }

    .mark-as-read {
        display: flex;
        justify-content: start;
        margin-bottom: 25px;
        margin-top: 15px;

        p {
            color: ${props => props.config.themeLayout.buttonBackground};
            font-weight: bold;
            width: max-content;
        }
        p:hover {
            opacity: 0.5;
            cursor: pointer;
        }
    }

    .mark-one-as-read {
        display: flex;
        justify-content: space-between;


        p {
            color: ${props => props.config.themeLayout.subtext} !important;
        }
    }

    .notification {
        padding: 10px 16px;
        border-radius: 24px;
        border: 1px solid #ccc;
        margin-top: 5px;
        margin-bottom: 10px;

        p {
            text-align: end;
            font-size: 15px;
            font-weight: bold;
            color: ${props => props.config?.themeLayout?.title};
        }

        a {
            :hover {
                color: ${props => props.config.themeLayout.title};
            }
        }
    }

    .photo {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &.default-bg {
            background-color: ${props => props.config.themeLayout.subtext};
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }

        .default-user {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-image: ${props => `url(${props.defaultUser})`};
            background-size: cover;
            opacity: 0.5;
        }
    }

    .accept-invite {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: max-content;

        p {
            color: ${props => props.config.themeLayout.buttonBackground};
            font-size: 16px;
            margin-right: 5px;
        }
    }

    .no-results {
        margin-top: 30px;
    }
`;

export default Notifications;
