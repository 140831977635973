import { useState, useMemo, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withRouter } from 'react-router';

import HeaderUserV2 from './HeaderUserV2.js';
import HeaderMobile from './HeaderMobile.js';
import ToolHelper from "../utils/ToolHelper";
import styled from '@emotion/styled';
import { BREAKPOINTS } from '../configs/consts'
// import plataforma from '../assets/plataforma.png';
import plataforma from '../assets/plataformaNOVO.png';
import CoreLink from '../Molecules/CoreLink.js';
import { Bell } from 'react-feather';
import Notifications from '../Notifications/index.js';
import Button from '../Atoms/Button.js';
import Campaigns from '../Campaigns/index.js';

const excludedScreensSalesRush = [
  "Meus Resultados",
  "Sorteios",
  "Pesquisas",
  "Clientes Focalizados",
  "Aprovação de Valores",
  "Regulamentos",
];

function HeaderV2Base(props) {
  const location = useLocation();
  const [isMenuUserOpen, setIsMenuUserOpen] = useState(false);
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false);
  const [headerConfig, setHeaderConfig] = useState({});
  const [notifications, setNotifications] = useState([]);

  const { data, userHelper, notification, getConfigFile, routes, openGlobalPopup, hideLinksAndMenu, history } = props;

  const HIDE_LINKS_AND_MENU =
    history.location.pathname === routes.termsAgreement.path
    || (history.location.pathname === routes.aboutProgram.path && !Object.keys(props.data?.user).length)

  const HAS_SALES_RUSH = data?.user?.acg_ProfileCodes__c?.includes("rushdevendas");

  const IS_FARMER = data?.user?.acg_ProfileCodes__c?.includes('agricultor');

  const config = useMemo(() => ToolHelper.getThemedConfig(props, 'Header.preset'), [props]);

  useEffect(() => {
    loadHeaderConfig();
    loadPushNotifications();
  }, [global.userType])

  const loadPushNotifications = async () => {
    try {
      const notificationsList = await props.userHelper.getPushNotifications();
      setNotifications(notificationsList);
    } catch (error) {

    }
  }

  const loadHeaderConfig = async () => {
    const importedFile = await ToolHelper.importByUserType('header', getConfigFile);
    setHeaderConfig(importedFile.default);
  }

  const toggleMenuUser = (doOpen) => {
    let willOpen = !isMenuUserOpen;

    if (doOpen !== undefined) {
      willOpen = doOpen;
    }

    if (willOpen) {
      toggleMenuMobile(false);
    }

    setIsMenuUserOpen(willOpen);
  };

  const toggleMenuMobile = (doOpen) => {
    let willOpen = !isMenuMobileOpen;

    if (doOpen !== undefined) {
      willOpen = doOpen;
    }

    if (willOpen) {
      toggleMenuUser(false);
    }

    setIsMenuMobileOpen(willOpen);
  };

  const openNotifications = () => {
    props.rightPanel.setMaxWidth(520);
    props.rightPanel.setContent(() =>
      <Notifications
        {...props}
        refNotifications={notifications}
        refSetNotifications={setNotifications}
      />
    );
    props.rightPanel.setIsOpen(true);
  }

  const openCampaigns = () => {
    props.rightPanel.setMaxWidth(576);
    props.rightPanel.setContent(() =>
      <Campaigns
        {...props}
      />
    );
    props.rightPanel.setIsOpen(true);
  }

  return (
    <>
      <StyledHeader headerConfig={headerConfig} showHeader={props.data.showHeader} config={config} data={props.data}>
        {HIDE_LINKS_AND_MENU ? null : (
          <div className={'links-line'}>
            <div className={'links-container'}>
              {headerConfig?.links?.map((link, index) => {
                if (HAS_SALES_RUSH && excludedScreensSalesRush.includes(link.label)) {
                  return;
                }

                if (link.label === "Campanhas") {
                  return <FakeCoreLinkStyled onClick={openCampaigns} key={index}>{link.label}</FakeCoreLinkStyled>
                }

                return <CoreLinkStyled {...props} exact {...link} domain={config?.firebaseConfig?.authDomain} key={index} />
              })}
              {IS_FARMER && (
                <div className="notifications" onClick={openNotifications} id="btnNotifications">
                  <Bell
                    width={20}
                    height={20}
                    color={config.themeLayout.buttonBackground}
                    fill={config.themeLayout.buttonBackground}
                  />
                  <span
                    style={{ backgroundColor: notifications?.length > 0 ? config.themeLayout.error : "" }}
                  >
                    {notifications?.length > 0 ? notifications?.length : ""}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
        <div className={'header-line'}>
          <div className={'header-container'}>
            <Link to={HIDE_LINKS_AND_MENU ? "/" : routes.home.path} className={'link-logo'}>
              <img src={headerConfig?.logo} className={'logo'} alt="" />
            </Link>
            {/* <div className={'slogan'}>
              <img src={plataforma} alt="" />
            </div> */}
            {HIDE_LINKS_AND_MENU ? null : (
              <div className={'side-container'}>
                <HeaderMobile isFarmer={IS_FARMER} openNotifications={openNotifications} openCampaigns={openCampaigns} isOpen={isMenuMobileOpen} toggleIsOpen={toggleMenuMobile} config={config} headerConfig={headerConfig} rightPanel={props.rightPanel} data={props.data} dataHelper={props.dataHelper} />
                <HeaderUserV2 isOpen={isMenuUserOpen} toggleIsOpen={toggleMenuUser} data={data} config={config} userHelper={userHelper} notification={notification} headerConfig={headerConfig} openGlobalPopup={openGlobalPopup} rightPanel={props.rightPanel} dataHelper={props.dataHelper} message={props.message} />
              </div>
            )}
          </div>
        </div>
      </StyledHeader>
      {/* <StyledWave showHeader={props.data.showHeader}>
        <img src={ondaCinza} />
      </StyledWave> */}
    </>
  );
}

const HeaderV2 = withRouter(HeaderV2Base)

export default HeaderV2;

/* style */

const StyledHeader = styled.div`
  display: ${props => props.showHeader ? 'block' : 'none'};
  position: sticky;
  top: 0;
  z-index: 9999;

  .links-line {
    // background: #224179; // ${props => props.config?.themeLayout?.background || 'transparent'};
    background: ${props => props.config?.themeLayout?.background || 'transparent'};
    flex: 1;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    /* padding: 0 110px; */

    @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
      display: none;
    }

    .links-container{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: ${props => props.config?.layout?.CONTAINER_SIZE + 'px' || 'none'};
      margin: auto;
      padding: 10px 0;
    }
  }

  .notifications {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      width: 25px;
      height: 18px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
  }

  .header-line {
    background: #f6f6f6;
    flex: 1;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    display: flex;
    padding: 20px 0;

    .header-container{
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: ${props => props.config?.layout?.CONTAINER_SIZE + 'px' || 'none'};
      margin: 8px auto;
      padding-left: 20px;
      /* padding: 0 110px; */

      .link-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0;
  
        .logo {
          display: inline-block;
          width: clamp(100px,calc(20vw),225px);
        }
      }
  
      .slogan{
        font-weight: bold;
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: max(2vw,10px);

        img {
          width: clamp(150px,20vw,300px);
        }
      }
  
      .side-container {
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
  }

  .wave-line{
  }
`

export const FakeCoreLinkStyled = styled.div`
  text-decoration: none;
  color: #FFFFFF;
  font-size: 1.1em;
  font-weight: bold;
  cursor: pointer;
  &.active {
    color: '#ACC332';
  }
  &:hover {
    opacity: 0.7;
  }
`

export const CoreLinkStyled = styled(CoreLink)`
  text-decoration: none;
  color: #FFFFFF;
  font-size: 1.1em;
  font-weight: bold;
  &.active {
    color: '#ACC332';
  }
  &:hover {
    opacity: 0.7;
  }

`;

// const StyledWave = styled.div`
//   display: ${props => props.showHeader ? 'block' : 'none'};

//   img{
//     width: 100%;
//   }
// `s