import styled from '@emotion/styled';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import StyledInput from './StyledFormInput';

const FormSelect = forwardRef((props,ref) => {
  const [ isEmptyDisabled, setIsEmptyDisabled ] = useState(false)
  const { input, config, layout, value, extraOptions } = props;

  

  useEffect(() => {
    if (extraOptions) {
      input.options = extraOptions;
    }
  }, [extraOptions])

  useImperativeHandle(ref, () => ({
    reset() {
      input.setValue('');
      input.inputRef.current.value = '';
      onChange();
    }
  }))

  useEffect(() => {
    setIsEmptyDisabled(
      !input.validations?.filter(validation => validation.type === 'required').length
    )
  },[])

  useEffect(() => {
    setTimeout(() => {
      if(input?.startValue && input.inputRef.current){
        input.inputRef.current.value = input.startValue;
        onChange();
      }
    },500)

  },[input.startValue])

  const onChange = () => {
    if(input.onChange){
      input.onChange(input.inputRef.current.value);
    }
  }

  return <StyledFormSelect config={config}>
    <StyledInput config={config} className={`input__group ${layout} ${input?.type}-input`}>
      <span className={'select-arrow'}><i className={'fa fa-angle-down'} /></span>
      <select data-testid='select' {...props}  value={value ? value : undefined} ref={input?.inputRef} onInput={onChange} {...input.inputOptions} required>
        <option key={"__empty"} value={""} disabled={isEmptyDisabled}></option>
       {extraOptions ? extraOptions.map((option) => {
          return <option key={option.value} value={option.value}>{option.label}</option>
        })
        :
        input?.options?.length > 0 && input?.options?.map((option) => {
          return <option key={option.value} value={option.value}>{option.label}</option>
        })}

      </select>
      <label>
        <div className={'label-bg'}/>
        <span className={'label-show'}>{input?.label || 'Input'}</span>
        <span className={'label-placeholder'}>{input?.label || 'Input'}</span>
      </label>
      <p className="error">{input?.error}</p>
      {input?.append && <div className="append">{input?.append}</div>}
    </StyledInput>
  </StyledFormSelect>
})

export default FormSelect;

/* style */

const StyledFormSelect = styled.div`
  .select-input{
    .select-arrow{
      position: absolute;
      z-index: 2;
      right: 0.5em;
      top: 0.4em;
      background: ${props => props.config?.themeLayout?.buttonSecondaryBackground};
      width: 1.5em;
      height: 1.5em;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      
      i{
        font-weight: bold;
        color: ${props => props.config?.themeLayout?.link};
      }
    }

    select{
      position: relative;
      appearance: none;
      cursor: pointer;
    }

    label{
      top: 0.6em;
    }
  }
`