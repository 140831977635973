// const firebaseConfigDEV = {
//   apiKey: "AIzaSyDpZG2BjNLNH7SSKmbVCDDkOFkyEgYXsHQ",
//   authDomain: "syngenta-dev-c4409.firebaseapp.com",
//   projectId: "syngenta-dev-c4409",
//   storageBucket: "syngenta-dev-c4409.appspot.com",
//   messagingSenderId: "969954336618",
//   appId: "1:969954336618:web:c9ab379a5348219a624f13",
//   trackingId: "G-GSJ18WKGLV",
// };

// const firebaseConfigUAT = {
//   apiKey: "AIzaSyAzTOP-rxKVnIghzWZLUhZ836iJkGU1eTw",
//   authDomain: "syngenta-homol.firebaseapp.com",
//   projectId: "syngenta-homol",
//   storageBucket: "syngenta-homol.appspot.com",
//   messagingSenderId: "798481222915",
//   appId: "1:798481222915:web:208aeeee785a664cd32d36",
//   trackingId: "G-QD39CW7TYH"
// };

// const firebaseConfigPROD = {
//   apiKey: "AIzaSyDz5BqNWMp_YWJ7Ed3iV61LVODK-ApdEv0",
//   authDomain: "syngenta-1229a.firebaseapp.com",
//   projectId: "syngenta-1229a",
//   storageBucket: "syngenta-1229a.appspot.com",
//   messagingSenderId: "961014963235",
//   appId: "1:961014963235:web:13fe95ede592357c5ea596",
//   // trackingId: "GTM-KS77X9HB", << Não usar. A tag está inserida manualmente em public/index.html
// };

// const returnConfig = () => {
//   let config = '';
//   switch (process.env.REACT_APP_ENV) {
//     case 'DEV':
//       config = firebaseConfigDEV
//       break;
//     case 'UAT':
//       config = firebaseConfigUAT
//       break;
//     case 'PROD':
//       config = firebaseConfigPROD
//       break;
//     default:
//       break;
//   }

//   return config
// }

// const firebaseConfig = returnConfig()
const firebaseConfig = {
  apiKey: "AIzaSyDz5BqNWMp_YWJ7Ed3iV61LVODK-ApdEv0",
  authDomain: "syngenta-1229a.firebaseapp.com",
  projectId: "syngenta-1229a",
  storageBucket: "syngenta-1229a.appspot.com",
  messagingSenderId: "961014963235",
  appId: "1:961014963235:web:13fe95ede592357c5ea596",
  // trackingId: "GTM-KS77X9HB", << Não usar. A tag está inserida manualmente em public/index.html
};


export default firebaseConfig;